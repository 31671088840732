<template>
<div>
    <div v-if="editor" style="display:flex;flex-wrap: wrap;">
      <div v-for="(markVal, markKey) in editorMarks"
      :key="markKey">
        <div v-if="markVal.activeType">
          <button class="btn" @click="selectedMarksOpr(markVal)" :class="{ 'is-active': editorActiveClass(markVal) }">
            {{markKey}}
          </button>&nbsp;
        </div>
        <div v-else>
          <button class="btn" @click="selectedMarksOpr(markVal)">
            {{markKey}}
          </button>&nbsp;
        </div>
      </div>
  </div>
  <editor-content class="content" :editor="editor" />
  <v-btn block @click="preview_content">Send</v-btn>
</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent,
  },
  props:{
    Ordinal: Number,
  },
  data() {
    return {
      editor: null,
      editorMarks:{
        Bold: {editorName: 'bold', activeType: true},
        Italic: {editorName: 'italic', activeType: true},
        Strike: {editorName: 'strike', activeType: true},
        Paragraph: {editorName: 'paragraph', activeType: true},
        Blockquote: {editorName: 'blockquote', activeType: true},
        H1: {editorName: 'HeadingLvl1', activeType: true},
        H2: {editorName: 'HeadingLvl2', activeType: true},
        H3: {editorName: 'HeadingLvl3', activeType: true},
        H4: {editorName: 'HeadingLvl4', activeType: true},
        H5: {editorName: 'HeadingLvl5', activeType: true},
        H6: {editorName: 'HeadingLvl6', activeType: true},
        HorizontalRule: {editorName: 'HorizontalRule', activeType: false},
        HardBreak: {editorName: 'HardBreak', activeType: false},
        Undo: {editorName: 'undo', activeType: false},
        Redo: {editorName: 'redo', activeType: false},
      }
    }
  },
  mounted() {
    this.editor = new Editor({
      content: "",
      extensions: [
        StarterKit,
      ],
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods:{
    editorActiveClass(activeMarkObj){
      //console.log("in class active function, active mark is", activeMarkObj)
      if(activeMarkObj.editorName==="bold"){
        return this.editor.isActive('bold')
      }
      else if(activeMarkObj.editorName==="italic"){
        return this.editor.isActive('italic')
      }
      else if(activeMarkObj.editorName==="strike"){
        return this.editor.isActive('strike')
      }
      else if(activeMarkObj.editorName==="paragraph"){
        return this.editor.isActive('paragraph')
      }
      else if(activeMarkObj.editorName==="blockquote"){
        return this.editor.isActive('blockquote')
      }
      else if(activeMarkObj.editorName==="HeadingLvl1"){
        return this.editor.isActive('heading', { level: 1 })
      }
      else if(activeMarkObj.editorName==="HeadingLvl2"){
        return this.editor.isActive('heading', { level: 2 })
      }
      else if(activeMarkObj.editorName==="HeadingLvl3"){
        return this.editor.isActive('heading', { level: 3 })
      }
      else if(activeMarkObj.editorName==="HeadingLvl4"){
        return this.editor.isActive('heading', { level: 4 })
      }
      else if(activeMarkObj.editorName==="HeadingLvl5"){
        return this.editor.isActive('heading', { level: 5 })
      }
      else if(activeMarkObj.editorName==="HeadingLvl6"){
        return this.editor.isActive('heading', { level: 6 })
      }
      return false
    },
    selectedMarksOpr(activeMarkObj){
      //console.log("active mark is", activeMarkObj)
      if(activeMarkObj.editorName==="bold"){
        this.editor.chain().focus().toggleBold().run()
      }
      else if(activeMarkObj.editorName==="italic"){
        this.editor.chain().focus().toggleItalic().run()
      }
      else if(activeMarkObj.editorName==="strike"){
        this.editor.chain().focus().toggleStrike().run()
      }
      else if(activeMarkObj.editorName==="paragraph"){
        this.editor.chain().focus().setParagraph().run()
      }
      else if(activeMarkObj.editorName==="blockquote"){
        this.editor.chain().focus().toggleBlockquote().run()
      }
      else if(activeMarkObj.editorName==="HeadingLvl1"){
        this.editor.chain().focus().toggleHeading({ level: 1 }).run()
      }
      else if(activeMarkObj.editorName==="HeadingLvl2"){
        this.editor.chain().focus().toggleHeading({ level: 2 }).run()
      }
      else if(activeMarkObj.editorName==="HeadingLvl3"){
        this.editor.chain().focus().toggleHeading({ level: 3 }).run()
      }
      else if(activeMarkObj.editorName==="HeadingLvl4"){
        this.editor.chain().focus().toggleHeading({ level: 4 }).run()
      }
      else if(activeMarkObj.editorName==="HeadingLvl5"){
        this.editor.chain().focus().toggleHeading({ level: 5 }).run()
      }
      else if(activeMarkObj.editorName==="HeadingLvl6"){
        this.editor.chain().focus().toggleHeading({ level: 6 }).run()
      }
      else if(activeMarkObj.editorName==="undo"){
        this.editor.chain().focus().undo().run()
      }
      else if(activeMarkObj.editorName==="redo"){
        this.editor.chain().focus().redo().run()
      }
      else if(activeMarkObj.editorName==="HorizontalRule"){
        this.editor.chain().focus().setHorizontalRule().run()
      }
      else if(activeMarkObj.editorName==="HardBreak"){
        this.editor.chain().focus().setHardBreak().run()
      }
    },
    preview_content(){
      let emitData={}
      emitData["content"] = this.editor.getHTML()
      emitData["type"] = "Rich Text"
      console.log("emit data", emitData)
      this.editor.commands.clearContent(true)
      this.$emit('clicked', emitData)
    }
  }
}
</script>

<style lang="scss" type="text/scss">
/* Basic editor styles */
.content {
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
        border: 1px solid #dbdbdb;
        display: block;
        max-width: 100%;
        min-width: 100%;
        padding: 0.625em;
        resize: vertical;
        background-color: white;
        border-radius: 4px;
        text-align: left;
    }
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
  
  img {
    max-width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #f88a68;
    }
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}
.ProseMirror:focus {
    outline: none;
  }

.btn {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 5px 15px;
  font-size: 16px;
  cursor: pointer;
}

.btn:hover {
  background-color: #070707;
  color: white;
}
</style>
<template>
  <div>
    <div class="container">
      <div class="box">
        <v-textarea 
          v-model="formulaValue" type="text" placeholder="LaTex Format for ex. x^2+y^2=\frac{x}{y}"
          @input="preview_content"
        ></v-textarea>
      </div>
      <div class="box">
        <v-card height="140">
          <div id="katext_preview">
            <h1 style="text-align:center;"> Preview </h1>
          </div>
        </v-card>
      </div><br>
    </div>
    <div style="text-align:center;">
      <v-btn @click="send_content">Send</v-btn><br><br>
      <a href="https://katex.org/docs/support_table.html">More About KaTeX</a><br><br>
    </div>
  </div>
</template>

<script>
import katex from 'katex';

export default {
  data() {
    return {
      formulaValue: "",
      previewFormula: "",
    }
  },
  methods:{
    preview_content(){
      this.previewFormula = (katex.renderToString(this.formulaValue) + "<br>")
      if(this.formulaValue===''){
        this.previewFormula = ''
      }
      document.getElementById('katext_preview').innerHTML = this.previewFormula
    },
    send_content(){
      let emitData={}
      //emitData["actualContent"] = this.formulaValue
      emitData["type"] = "KaTeX"
      emitData["content"] = (katex.renderToString(this.formulaValue) + "<br>")
      if(this.formulaValue===''){
        emitData["HTML"] = ''
      }
      this.formulaValue = ""
      document.getElementById('katext_preview').innerHTML = '<h1 style="text-align:center;"> Preview </h1>'
      this.$emit('clicked', emitData)
    }
  }
}
</script>

<style scoped>

* {
  box-sizing: border-box;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 117vh;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* For the border line between columns */
.border-between {
    border-right: 1px solid black;
}

.container {
  display: flex;
  flex-direction: row;
}

.box {
  width: 50%;
  padding: 20px;
}


@media screen and (max-width: 992px){
  .container {
    flex-direction: column;
  }
  .box {
    width: 100%;
    padding: 20px;
  }
  .border-between {
    border-bottom: 1px solid black;
    border-right: 0px solid black;
  }
}
</style>
<template>
<div style="margin-left:25%;width:50%;">
      <div>
        <v-file-input
          v-model="imageInput"
          label="File input"
        ></v-file-input><br>
        <v-text-field v-model="imgTitle" type="text"
            style="width:90%;margin-left:5px;"
            prepend-icon="mdi-newspaper-variant-outline"
            placeholder="Title of Image"
        ></v-text-field>
        <v-btn outlined color="grey" @click="previewUploadedImg">Send</v-btn>
      </div>
</div>
</template>

<script>
import axios from 'axios';
import AppConfig from '../../config.js';

export default {
  props:{
    slot_part_id: String,
  },
  data() {
    return {
      //imagePath: "",
      UI_Base_URL: AppConfig.UI_Base_URL,
      work_api_Base_URL: AppConfig.Work_API_Base_URL,
      challenge_api_Base_URL: AppConfig.Challenge_API_Base_URL,
      sched_api_Base_URL: AppConfig.Sched_API_Base_URL,
      imageInput: null,
      imageCreatedUrl: "",
      imgTitle: "",
      respMsg: "",
      showRespMsg: false,
    }
  },
  methods:{
    async imgUploadToFS(){
      try{
        var imgFormData = new FormData();
        imgFormData.append("file", this.imageInput);
        //let imgDictData = {}
        //imgDictData["file"] = this.imageInput
        let uploadImgResp = await axios({
          method: "post",
          url:this.work_api_Base_URL + "/v/me/upload_chat_file/" + this.slot_part_id,
          data:imgFormData,
        })
        console.log("read event resp", uploadImgResp)
        this.imageCreatedUrl = uploadImgResp.data["file_url"]
      }catch(error){
        console.log("error in response of uploadImgResp", error.response)
        this.respMsg = error.response.headers["message"]
        this.showRespMsg = true
      }
    },
    imgToData(file){
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(file)
      })
    },
    async previewUploadedImg(){
      await this.imgUploadToFS();
      let emitData={}
      //const resData = await this.imgToData(this.imageInput)
      emitData["content"] = this.imgTitle
      emitData["file"] = this.imageCreatedUrl
      emitData["type"] = "Image"
      console.log("emitted data", emitData)
      this.imgTitle = ""
      this.imageInput = null
      this.$emit('clicked', emitData)
    }
  }
}
</script>
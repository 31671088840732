<template>
  <div>
    <div v-if="isUserTutorInSlot" style="text-align:left;">
      <v-navigation-drawer clipped app v-model="sys_menu_drawer"
        :mini-variant="sys_menu_mini"
        permanent
        :width="220"
      >
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="(sys_menu_item, keyNames) in sys_menu_items"
              :key="keyNames"
              link
            >
              <v-list-item-icon v-if="!sys_menu_item.dropdown" @click="menu_selected(sys_menu_item.title)">
                <v-icon>{{ sys_menu_item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-icon v-else>
                <v-menu offset-x open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">{{ sys_menu_item.icon }}</v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in sys_menu_item.itemList"
                      :key="index"
                    >
                      <v-list-item-title  @click="editorTypeSelected(item)">{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-icon>

              <v-list-item-content v-if="!sys_menu_item.dropdown" @click="menu_selected(sys_menu_item.title)">
                <v-list-item-title>{{ sys_menu_item.title }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content v-else>
                <v-menu offset-x open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">{{ sys_menu_item.title }}</v-list-item-title>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in sys_menu_item.itemList"
                      :key="index"
                    >
                      <v-list-item-title @click="editorTypeSelected(item)">{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group class="fixedBottom">
            <v-list-item @click.stop="sys_menu_mini = !sys_menu_mini">
              <v-list-item-icon>
                <v-btn
                  icon
                  class="collapse-icon"
                  :class="{ 'rotate-180': sys_menu_mini }"
                >
                  <v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>Collapse</v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-navigation-drawer clipped app v-model="context_menu_drawer"
        :mini-variant="context_menu_mini"
        permanent
        :width="180"
        right
      >
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="(context_menu_item, key) in context_menu_items"
              :key="context_menu_item.title"
              link
            >
              <v-list-item-icon>
                <v-icon @click="context_menu_selected(context_menu_item.title)">{{ context_menu_item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title @click="context_menu_selected(context_menu_item.title)">{{ key }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group class="fixedBottom">
            <v-list-item @click.stop="context_menu_mini = !context_menu_mini">
              <v-list-item-icon>
                <v-btn
                  icon
                  class="collapse-icon"
                  :class="{ 'rotate-180': context_menu_mini }"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>Collapse</v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-sheet>
        <v-card
          class="overflow-y-auto"
          :max-height="chalAreaMaxHeight"
        >
        <div v-for="index in totalCandidates" :key="index">
          <div :id="'meet_' + index" style="display: none;"></div>
        </div>
          <v-card-text>
            <h2>{{challengeName}}</h2><hr><br>
            <div id="challenge_content">
              Challenge content
            </div>
          </v-card-text>
        </v-card>
        <br>
        <v-card
          class="overflow-y-auto"
          height="400"
        >
          <h1 style="text-align:center;">Chat Area</h1>
          <v-row justify="center">
            <v-expansion-panels v-model="openedSlotPanel">
              <v-expansion-panel
                v-for="(cand,idx) in allCandidatesList"
                :key="idx"
              >
                <v-expansion-panel-header>{{ cand["uname"] }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card
                    class="overflow-y-auto"
                    height="400"
                  >
                    <div style="margin-left:20px;">
                      <div :id="'chatArea_' + (idx + 1).toString()" v-for="(chat, idx) in chatList[renderChatProtegeIdx]" :key="idx" style="overflow: hidden;position: relative;">
                        <div v-if="chat.incoming" class="incomingChatClass">
                          <div v-if="chat.type==='Image'">
                            <img width="400" :src="chat.src"/><br>
                          </div>
                          <div v-else>
                            <div v-html="chat.content"></div>
                          </div>
                        </div>
                        <div v-else class="outgoingChatClass">
                          <div v-if="chat.type==='Image'">
                            <img width="400" height="300" :src="chat.src"/><br>
                          </div>
                          <div v-else>
                            <div v-html="chat.content"></div>
                          </div>
                        </div><br><br>
                      </div>
                    </div>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card>
        <br>
        <v-card
          class="overflow-y-auto"
          height="300"
          v-if="inputEnabled"
        >
          <imageUpload v-if="editorIS==='Images'" :slot_part_id=selectedProtegeSlotPartID @clicked="textEmitted" />
          <richText v-else-if="editorIS==='Rich Text'" @clicked="textEmitted" />
          <kaTeX v-else @clicked="textEmitted" />
        </v-card>
      </v-sheet>
    </div>
    <div v-else>
      You do not have the access to join this Slot.<br>
    </div>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode';
import imageUpload from './ImageUpload.vue';
import kaTeX from './kaTexEditor.vue';
import richText from './RichTextEditor.vue';
import AppConfig from '../../config.js';
import axios from 'axios';
import katex from 'katex';
  export default {
    name: 'workbook_wid',
    components:{
      imageUpload,
      richText,
      kaTeX
    },
    data(){
        return{
            UI_Base_URL: AppConfig.UI_Base_URL,
            work_api_Base_URL: AppConfig.Work_API_Base_URL,
            challenge_api_Base_URL: AppConfig.Challenge_API_Base_URL,
            sched_api_Base_URL: AppConfig.Sched_API_Base_URL,
            route_wid: "",
            sys_menu_drawer: true,
            sys_menu_items: {
              ChalAreaInc: { title: 'Increase Chal Area', icon: 'mdi-magnify-plus', dropdown: false },
              ChalAreaDec: { title: 'Decrease Chal Area', icon: 'mdi-magnify-minus', dropdown: false },
              ChatCheck: { title: 'Chat-check', icon: 'mdi-chat-alert', dropdown: false },
              VideoToggle: { title: 'Video-toggle', icon: 'mdi-video-box', dropdown: false },
              AudioToggle: { title: 'Mic-toggle', icon: 'mdi-microphone', dropdown: false},
              HangupSelected : { title: 'Hangup-Selected', icon: 'mdi-phone-hangup', dropdown: false},
              Editors: { title: 'Editors', icon: 'mdi-clipboard-edit', dropdown: true, itemList: ["Images", "LaTeX", "Rich Text"] },
            },
            context_menu_items: {
              Home: { title: 'Home', icon: 'mdi-home-city' },
              Account: { title: 'My Account', icon: 'mdi-account' },
              Users: { title: 'Users', icon: 'mdi-account-group-outline' },
            },
            sys_menu_mini: true,
            context_menu_mini: true,
            context_menu_drawer: true,
            api: [],
            room_to_join: "test romm",
            msgToMe: [],
            renderChatStr: [],
            chalAreaMaxHeight: 250,
            challengeName: "",
            allCandidatesList: [],
            totalCandidates: 0,
            selectedSlot: 0, // from 1 to n
            openedSlotPanel: undefined, // start from 0 to n-1
            user_acc_token: {},
            editorIS: "Rich Text",
            inputEnabled: false,
            isUserTutorInSlot: false,
            slotInfo: {},
            selectedProtegeSlotPartID: "",
            chatList: [],
            renderChatProtegeIdx: 0,
        }
    },
    watch:{
      openedSlotPanel(newOpenedSlotPanelVal, oldOpenedSlotPanelVal){
        console.log("--new--", newOpenedSlotPanelVal, "---oldVal---", oldOpenedSlotPanelVal)
        this.selectedSlotFn()
        this.muteAllOthers(oldOpenedSlotPanelVal, newOpenedSlotPanelVal)
      }
    },
    async mounted(){
      console.log("before mount of workbook wid")
      console.log("route wid is:", this.$route.params.wid)
      this.user_acc_token = jwt_decode(this.$keycloak["token"])
      console.log("--user info--", this.user_acc_token)
      if(this.$route.params.wid!= undefined){
        this.route_wid= this.$route.params.wid
        console.log("valid workbook id")
        await this.read_slotFn(this.route_wid)
        if(this.slotInfo["tutor"]===this.user_acc_token["sub"]){
          this.isUserTutorInSlot = true
          await this.getAllParticipants(this.route_wid);
          this.challengeName = this.slotInfo["challenges"][0]["title"]
          await this.renderChalFn(this.slotInfo["challenges"][0]["id"]);
          this.openRoom();
        }
        console.log("value of isUserTutorInSlot in beforemount is", this.isUserTutorInSlot)
      }
    },
    methods: {
      async getAllParticipants(slot_id){
        let getCandidateResp = await axios.get(this.work_api_Base_URL + "/v/me/get_all_participants/" + slot_id)
        console.log("all candidate resp", getCandidateResp)
        this.totalCandidates = getCandidateResp.data.length
        // TODO get past chat history from db
        for(let idx=0;idx<this.totalCandidates;idx++){
          this.allCandidatesList.push({fullname: getCandidateResp.data[idx]["name"], slot_participant_id: getCandidateResp.data[idx]["slot_participant_id"], user_id: getCandidateResp.data[idx]["user_id"], slot_id: this.route_wid, uname: getCandidateResp.data[idx]["username"]})
          this.renderChatStr.push("")
          this.chatList.push([])
        }
        this.selectedSlot = 1
      },
      async renderChalFn(chalID){
        let challengeResp = await axios.get(this.challenge_api_Base_URL + "/v/me/seek_chal/" + chalID)
        console.log("challenge content resp", challengeResp)
        // render the challenge from challeneg details
        let chalParts = challengeResp.data["parts"]
        let renderChallenge=""
        for(let idx=0;idx<chalParts.length;idx++){
          //console.log("loop of preview content, render value", renderChallenge)
          if(chalParts[idx]["chal_part_class"] == 'Text'){
            renderChallenge=renderChallenge + "<div id='preview_" + (idx+1).toString() + "'>" + chalParts[idx]["chal_part_text"] + "</div>"
          }
          else if(chalParts[idx]["chal_part_class"] == 'LaTeX'){
            let renderFormat = (katex.renderToString(chalParts[idx]["chal_part_text"]) + "<br>")
            renderChallenge=renderChallenge + "<div id='preview_" + (idx+1).toString() + "'>" + renderFormat + "</div>"
          }
          else if(chalParts[idx]["chal_part_class"] == 'Image'){
            let resp = await axios.get(chalParts[idx]["url"], { responseType: 'arraybuffer' })
            .then((response) => {
            let image = btoa(
                new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
            });
            //console.log("img resp", resp)
            let ret = "<img style='height:300px;width:300px;' src='" + resp +"' title='" + chalParts[idx]["chal_part_text"] + "'/><br>"
            renderChallenge=renderChallenge + "<div id='preview_" + (idx+1).toString() + "'>" + ret + "</div>"
          }
        }
        document.getElementById("challenge_content").innerHTML = renderChallenge
      },
      async read_slotFn(slot_id){
        try{
          let readEventResp = await axios.get(this.sched_api_Base_URL + "/v/me/read_slot/" + slot_id)
          console.log("read event resp", readEventResp)
          this.slotInfo = readEventResp.data
        }catch(error){
          console.log("error in response of show event", error.response)
          this.respMsg = error.response.headers["message"]
          this.showRespMsg = true
        }
      },
      textEmitted(dataCatched){
        console.log("catched data is", dataCatched)
        // stringfify the caught object
        let msgStringify = JSON.stringify(dataCatched)
        console.log("msg type is", msgStringify)
        this.sendMsgFn(msgStringify)
      },
      editorTypeSelected(editorType){
        console.log("type of editor selected is:", editorType)
        this.editorIS = editorType
      },
      getRenderedSlotIdx(uname){
        let slotIdxID = -1;
        console.log("in getRenderedSlotIdx, totalCandidates is", this.totalCandidates)
        for(let idx=0;idx<this.totalCandidates;idx++){
          console.log(this.allCandidatesList)
          if(this.allCandidatesList[idx]["uname"] === uname){
            slotIdxID = idx;
          }
        }
        return slotIdxID
      },
      getUIDFromUname(uname){
        console.log("uname is ", uname)
        let retval = ""
        let slotIdxID = this.getRenderedSlotIdx(uname)
        if(slotIdxID != -1){
          console.log("slotIdxId is -----", slotIdxID, "api", this.api[slotIdxID])
          let slotParti = this.api[slotIdxID].getParticipantsInfo();
          let totalParti = slotParti.length
          for(let idx=0;idx<totalParti;idx++){
            if(slotParti[idx]["displayName"] === uname){
              retval = slotParti[idx]["participantId"]
            }
          }
        }
        return retval
      },
      context_menu_selected(selected){
        if(selected === "Home" && this.selectedSlot>0){
          this.api[this.selectedSlot-1].isAudioAvailable().then(available => {
              console.log("----in context---", available)
          });
        }
      },
      menu_selected(selected){
        if(selected === "Chat-check"){
          console.log("placeholder for now")
        }
        else if(selected === "Video-toggle"){
          this.toggleVideo();
        }
        else if(selected === "Mic-toggle"){
          this.toggleAudio();
        }
        else if(selected === "Increase Chal Area"){
          if(this.chalAreaMaxHeight<=250){
            this.chalAreaMaxHeight = this.chalAreaMaxHeight + 100;
          }
        }
        else if(selected === "Decrease Chal Area"){
          if(this.chalAreaMaxHeight>=250){
            this.chalAreaMaxHeight = this.chalAreaMaxHeight - 100;
          }
        }
        else if(selected === "Hangup-Selected"){
          if(this.selectedSlot > 0){
            this.api[this.selectedSlot-1].executeCommand('hangup');
            //let workbookEndURL = this.UI_Base_URL + '/workbook'
            //window.location.href = workbookEndURL
          }
        }
      },
      toggleVideo(){
        if(this.selectedSlot > 0){
          this.api[this.selectedSlot-1].executeCommand('toggleVideo');
        }
      },
      toggleAudio(){
        if(this.selectedSlot > 0){
          this.api[this.selectedSlot-1].executeCommand('toggleAudio');
        }
      },
      async incomingChatFn(chatCatched){
        console.log("---chatCatched is---", chatCatched)
        this.msgToMe.push(chatCatched.message)
        let userRenderedIdx = this.getRenderedSlotIdx(chatCatched["nick"]) + 1
        if(userRenderedIdx > 0){
          await this.renderChatFn(chatCatched.message, true, userRenderedIdx)
          //this.renderChatStr[userRenderedIdx-1] = this.renderChatStr[userRenderedIdx-1] +"<br><br><p style='background-color: #a087ec; display: table; float: right; padding: 2px; border-radius: 0.25em;'>" + chatCatched.message + "</p>"
          //let chatAreaId = "chatArea_"+(userRenderedIdx.toString())
          //console.log("chat area id val is --->", chatAreaId)
          //document.getElementById(chatAreaId).innerHTML = this.renderChatStr[userRenderedIdx-1]
        }
      },
      audioMuteStatusChangedFn(muteStatus){
        console.log("---mute status---", muteStatus)
        if(muteStatus.muted){
          this.sys_menu_items.AudioToggle["icon"] = 'mdi-microphone-off'
        }
        else{
          this.sys_menu_items.AudioToggle["icon"] = 'mdi-microphone'
        }
      },
      async renderChatFn(chatToRender, incoming, renderToPanel){
        this.renderChatProtegeIdx = renderToPanel-1
        console.log("render the chat in chat area", chatToRender)
        let objChatToRender = JSON.parse(chatToRender)
        if(objChatToRender["type"] === "Image"){
          try{
            let imgGetResp = await axios.get(objChatToRender["file"], { responseType: 'arraybuffer' })
            .then((response) => {
              let image = btoa(
                  new Uint8Array(response.data)
                  .reduce((data, byte) => data + String.fromCharCode(byte), '')
              );
              return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
            });
            this.chatList[renderToPanel-1].push({incoming: incoming, type: "Image", src: imgGetResp})
            /*if(!incoming){
              this.renderChatStr[renderToPanel-1] = this.renderChatStr[renderToPanel-1] +"<br><br><div style='background-color: #7cedd3; display: table; float: right; padding: 2px; border-radius: 0.25em;'><img width='400' src='" + imgGetResp + "'></div>"
            }
            else{
              this.renderChatStr[renderToPanel-1] = this.renderChatStr[renderToPanel-1] +"<br><br><div style='background-color: #a087ec; display: table; float: left; padding: 2px; border-radius: 0.25em;'><img width='400' src='" + imgGetResp + "'></div>"
            }
            document.getElementById("chatArea_"+renderToPanel).innerHTML = this.renderChatStr[renderToPanel-1]*/
          }catch(error){
            console.log("error in response of img get", error.response)
            this.respMsg = error.response.headers["message"]
            this.showRespMsg = true
          }
        }
        else if(objChatToRender["type"] === "KaTeX"){
          this.chatList[renderToPanel-1].push({incoming: incoming, type: "KaTeX", content: objChatToRender["content"]})
          /*if(!incoming){
            this.renderChatStr[renderToPanel-1] = this.renderChatStr[renderToPanel-1] +"<br><br><div style='background-color: #7cedd3; display: table; float: right; padding: 2px; border-radius: 0.25em;'>" + objChatToRender["content"] + "</div>"
          }
          else{
            this.renderChatStr[renderToPanel-1] = this.renderChatStr[renderToPanel-1] +"<br><br><div style='background-color: #a087ec; display: table; float: left; padding: 2px; border-radius: 0.25em;'>" + objChatToRender["content"] + "</div>"
          }
          document.getElementById("chatArea_"+renderToPanel).innerHTML = this.renderChatStr[renderToPanel-1]*/
        }
        else if(objChatToRender["type"] === "Rich Text"){
          this.chatList[renderToPanel-1].push({incoming: incoming, type: "Rich Text", content: objChatToRender["content"]})
          /*if(!incoming){
            this.renderChatStr[renderToPanel-1] = this.renderChatStr[renderToPanel-1] +"<br><br><div style='background-color: #7cedd3; display: table; float: right; padding: 2px; border-radius: 0.25em;'>" + objChatToRender["content"] + "</div>"
          }
          else{
            this.renderChatStr[renderToPanel-1] = this.renderChatStr[renderToPanel-1] +"<br><br><div style='background-color: #a087ec; display: table; float: left; padding: 2px; border-radius: 0.25em;'>" + objChatToRender["content"] + "</div>"
          }
          document.getElementById("chatArea_"+renderToPanel).innerHTML = this.renderChatStr[renderToPanel-1]*/
        }
      },
      async sendMsgFn(msgToSend){
        let otherParticipant = ""
        if(this.selectedSlot > 0){
          for(const key in this.api[this.selectedSlot-1]._participants){
            if(key != this.api[this.selectedSlot-1]._myUserID){
              otherParticipant = key
            }
          }
          await this.renderChatFn(msgToSend, false, this.selectedSlot)
          //this.renderChatStr[this.selectedSlot-1] = this.renderChatStr[this.selectedSlot-1] +"<br><br><p style='background-color: #7cedd3; display: table; float: left; padding: 2px; border-radius: 0.25em;'>" + this.msgToSend + "</p>"
          //document.getElementById("chatArea_"+this.selectedSlot).innerHTML = this.renderChatStr[this.selectedSlot-1]
          //console.log("----render chat vals------", this.renderChatStr[this.selectedSlot-1])
          console.log("----other participant------", otherParticipant)
          this.api[this.selectedSlot-1].executeCommand('sendChatMessage',
              msgToSend, //the text message
              otherParticipant, // the receiving participant ID or empty string/undefined for group chat.
              false // true if the privacy notification should be ignored. Defaulted to false.
          );
        }
      },
      startConference() {
        try {
          const domain = "m.rasree.com";
          console.log("total candidate in startConference", this.totalCandidates)
          console.log("allCandidatesList in startConference", this.allCandidatesList)
          for(let idx=0;idx<this.totalCandidates;idx++){
            this.api[idx] = null;
            let options = {
              roomName: this.allCandidatesList[idx]["slot_participant_id"],
              height: 500,
              parentNode: document.querySelector("#meet_"+ (idx + 1).toString()),
              configOverwrite: {
                startWithVideoMuted: true,
                startWithAudioMuted: true,
              },
              userInfo: {
                  email: this.user_acc_token["email"],
                  displayName: this.user_acc_token["preferred_username"]
              }
            };

            this.api[idx] = new window.JitsiMeetExternalAPI(domain, options);
            this.api[idx].addListener('incomingMessage', this.incomingChatFn);
            // Change audio toggle icon
            this.api[idx].addListener('audioMuteStatusChanged', this.audioMuteStatusChangedFn);
            //console.log("--------api--------", this.api)
          }
        } catch (error) {
          console.error("Failed to load Jitsi API", error);
        }
      },
      openRoom() {
        // verify the JitsiMeetExternalAPI constructor is added to the global..
        if (window.JitsiMeetExternalAPI) {
          this.startConference();
        } else alert("Jitsi Meet API script not loaded");
      },
      selectedSlotFn(){
        console.log("panel------", this.openedSlotPanel)
        if(this.openedSlotPanel === undefined){
          this.selectedSlot = -1
          this.inputEnabled = false
        }
        else{
          this.selectedSlot = this.openedSlotPanel + 1
          this.inputEnabled = true
        }
        console.log("val of selectedSlot is", this.selectedSlot)
      },
      async muteAllOthers(oldSlot, newSlot){
        console.log("Mute all other except the opened slot:", oldSlot, "--", newSlot)
        if(oldSlot != undefined){
          let isPrevMuted = await this.api[oldSlot].isAudioMuted()
          if(!isPrevMuted){
            this.api[oldSlot].executeCommand('toggleAudio');
          }
          this.api[oldSlot].executeCommand('muteEveryone', 'audio');
          this.api[oldSlot].executeCommand('toggleModeration',
              true,
              'audio' // can be 'audio' (default) or 'video'
          );
          //this.api[oldSlot].executeCommand('muteEveryone', 'audio');
          //console.log("----x is --------", this.api[oldSlot]._participants)
        }
        if(newSlot != undefined){
          let newSlotUname = this.allCandidatesList[newSlot]["uname"]
          this.selectedProtegeSlotPartID = this.allCandidatesList[newSlot]["slot_participant_id"]
          console.log("--newSlotProtege name is---", newSlotUname)
          let protegeId = this.getUIDFromUname(newSlotUname)
          this.api[newSlot].executeCommand('askToUnmute',
              protegeId
          );
        }
      },
    },
  }
</script>

<style>

.collapse-icon {
  position: absolute;
  bottom:0;
  color: #0e0aec !important;
  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}

.fixedBottom {
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}

.outgoingChatClass {
  background-color: #7cedd3; 
  display: table; 
  float: right; 
  padding: 2px; 
  border-radius: 0.25em;
}

.incomingChatClass {
  background-color: #a087ec; 
  display: table; 
  float: left; 
  padding: 2px; 
  border-radius: 0.25em;
}

</style>